import React, { Fragment } from 'react';
import { Head, withRouteData } from 'react-static';

import Feed from '../containers/Feed';
import Header from '../components/Header';
// import Signup from '../components/Signup';
import { Box, Heading, Text } from '../components/system';

const Index = withRouteData(({ categories, deals, gifts }) => (
  <Fragment>
    <Header asFilter />
    <Box py={[5]} px={[3, null, 5]} bg="white">
      <Head>
        <title>Daily Deals</title>
        <meta
          name="description"
          content="Top NZ deals and discounts on protein powder, bodybuilding supplements, fat burners, vitamins &amp; oils, creatine, endurance products, and more."
        />
      </Head>
      <Box width={1} mt={2} mb={[3, null, 4]} px={3} textAlign="center">
        <Heading fontWeight="bold" fontSize={[3, 4, 5]}>
          Top NZ Supplement Deals
        </Heading>
      </Box>
      <Box width={1} mt={[2, 3, null, 4]} mb={[4, null, 5]} px={[3,4,5]} textAlign="center">
        <Text as="p" fontSize={[1, 2, 3]}>
          Check out the top <strong>whey deals</strong>, pre workout specials, and more supplements on sale right here. From <strong>clearance supplements</strong> to discounts on protein powder, fat burners, creatine, and vitamins.
        </Text>
      </Box>
      {categories.map((category, i) => (
        <Feed
          key={`${i}-${category.handle}`}
          category={category}
          items={deals.filter(deal => deal.categories.includes(category.handle))}
          gifts={gifts}
        />
      ))}
    </Box>
    {/* <Signup /> */}
  </Fragment>
));

export default Index;
